module.exports = {
  name: "Santhosh Chinnasamy",
  designation: "Software Engineer",
  website: "santhosh-chinnasamy.github.io",
  photo:
    "https://res.cloudinary.com/santhosh-chinnasamy/image/upload/v1630117719/personal/santhosh.jpg",
  startdate: "2019-06-01",
  social: {
    mail: "csesanthosh15@gmail.com",
    github: "https://github.com/santhosh-chinnasamy",
    linkedin: "https://www.linkedin.com/in/santhosh-chinnasamy/",
    blog: "https://bleedbytes.in",
    instagram: "https://www.instagram.com/santh0.sh/",
    twitter: "https://twitter.com/santhoshdvlpr",
  },
  stack: [
    "javascript",
    "node.js",
    "express",
    "react",
    "ruby",
    "rubyonrails",
    "html",
    "css",
    "php",
    "mongodb",
    "postgres",
    "docker",
    "git",
    "ci/cd",
    "aws",
    "microservices",
    "bash",
    "iot",
    "redis",
    "figma",
  ],
};
